








































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import ThingsFunctionListComponent from '../things-function/things-function-list.vue';
import ThingsFeatureService from '@/service/things-feature';
import { ThingsFeatureEntityModel } from '@/entity-model/things-feature-entity';
import { ThingsEntityType, ViewModeType } from '@/model/enum';
import FormDialogComponent from '@/mixins/form-dialog-component';

@Component({
    components: {
        'things-function-list-component': ThingsFunctionListComponent
    }
})
export default class ThingsFeatureDetailDrawer extends DrawerComponent {
    ThingsEntityType = ThingsEntityType;
    thingsFeatureModel: ThingsFeatureEntityModel = new ThingsFeatureEntityModel();
    thingsFeatureId: string;

    // created() {
    //     this.init();
    // }

    // get TemplateId() {
    //     return this.$route.params.id;
    // }

    drawerShow(model: ThingsFeatureEntityModel) {
        this.drawerOpen(model.id, model.name);
        this.init(model.id);
    }

    init(modelId: string) {
        this.thingsFeatureId = modelId;
        this.startFullScreenLoading();
        ThingsFeatureService.retrieve(modelId)
            .then(res => {
                this.thingsFeatureModel = new ThingsFeatureEntityModel().toModel(res);
            })
            .catch(error => {
                this.thingsFeatureModel = new ThingsFeatureEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        this.init(this.thingsFeatureId);
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<ThingsFeatureEntityModel>).dialogOpen(this.thingsFeatureModel, ThingsFeatureService, ViewModeType.UPDATE);
    }
}
